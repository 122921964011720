import React from 'react'
import {PortfolioProjects} from '../constants/constants.js'
import ButtonLink from '../components/ButtonLink'

const Projects = () => {
  return (
    <section className='p-5 mt-10'>
        <div>
        <h3 className='text-pearl-900 text-3xl py-1 md:text-4xl dark:text-pearl-500'>Portfolio</h3>
        <p className='text-left text-md my-5 py-5 leading-8 text-pearl-900 md:text-xl dark:text-pearl-500'>Here's a glimpse of projects I've worked on through freelance work, my career, and in school.</p>
        </div>
        <div className='flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap'>
            { PortfolioProjects.map((card, index) => (
                <div key={index} className='rounded-lg shadow-lg basis-1/3 overflow-hidden flex-1 dark:bg-slate-900'>
                    <img src={card.image} alt={card.title} className='h-100' />
                    <h3 className='text-pearl-900 font-semibold px-10 pt-10 text-md dark:text-pearl-500'>
                        {card.title} { 
                            card.archive && (<em className='px-2 py-1 font-bold mr-1 rounded-sm bg-pearl-50 dark:bg-slate-800'>(Archive Project)</em>)
                        }
                    </h3>
                    <p className='px-10 py-6 text-md leading-8 text-pearl-900 dark:text-pearl-500'>
                        
                        {card.description}
                    </p>

                    { 
                        card.btnText && (<ButtonLink link={card.link} text={card.btnText}/>)
                    }
                    
                </div>
            ))}
        </div>
    </section>
  )
}

export default Projects