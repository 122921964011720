import React from 'react';

const Button = ({ link, text }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
        <button className='ml-8 mb-10 px-5 py-2.5 flex justify-center align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-pearl-400 to-pearl-500 text-white transition-all duration-300 hover:text-pearl-900 rounded-md dark:text-pearl-950 dark:hover:text-white'>
            {text}
        </button>
    </a>
  );
};

export default Button;

