import project1 from '../media/android-001.jpg'
import project2 from '../media/project-001.jpg'
import project3 from '../media/project-002.jpg'
import project4 from '../media/project-003.jpg'
import project5 from '../media/project-004.jpg'
import project6 from '../media/project-005.jpg'

const PortfolioProjects = [
  {
    image: `${project5}`,
    alt: "",
    archive: false,
    title: "GDIT ATOaaS System Protoype",
    link: "https://github.com/awil/NIST_Automation",
    description:
      "I collaborated on a senior project to develop an automated Authorization to Operate as a Service (ATOaaS) solution for GDIT, streamlining and simplifying the auditing process. My role involved developing/coding an object oriented backend written in PHP and MySQL, setting up the site on an AWS server, and testing/debugging.",
      btnText: "View on GitHub",
  },
  {
    image: `${project1}`,
    alt: "",
    archive: false,
    title: "Catchin' Crawfish,",
    link: "https://vimeo.com/manage/videos/836094363",
    description:
      "As an individual project, I designed and developed an Android App called \"Catchin' Crawfish,\" a quirky match-three game where players aim to catch crawfish.",
    btnText: "View Demo",
  },
  {
    image: `${project6}`,
    alt: "",
    archive: false,
    title: "Server-Side Project",
    link: "https://github.com/Smasson76/SportsPro-Technical-Support",
    description:
      "A collaborative group project, my role involved implementing the server-side code using PHP and MySQL, ensuring seamless communication and data management for the SportsPro Technical Application.",
    btnText: "View on GitHub",
  },
  {
    image: `${project2}`,
    alt: "",
    archive: false,
    title: "Walker OMV Express",
    link: "https://walkeromv.com/",
    description:
      "I developed the website for Walker OMV Express to display their services, location and a contact page.",
    btnText: "Visit Walker OMV Express",
  },
  {
    image: `${project3}`,
    alt: "",
    archive: true,
    title: "@TheBarberShop",
    link: "https://github.com/",
    description:
      "I designed and developed a professional and user-friendly website for a client's barbershop, showcasing their services and team. Additionally, I designed the branding and signage for their office.",
    btnText: "",
  },
  {
    image: `${project4}`,
    alt: "",
    archive: true,
    title: "Walker Storehouse Mini Storage",
    link: "https://github.com/",
    description:
      "I coded a user-friendly website for Walker Storehouse, providing an intuitive interface for customers to explore available storage options, the facilities, and location.",
    btnText: "",
  },
];

const CurrentProjects = [
    {
      image: ``,
      alt: "",
    archive: false,
      title: "MarblMatch",
      link: "https://vimeo.com/836067724",
      description:
        "This is a personal project to learn more about iOS/Swift development and the Message App Extension API.",
        btnText: "View on GitHub",
    },
    {
      image: ``,
      alt: "",
    archive: false,
      title: "Catchin' Crawfish,",
      link: "https://vimeo.com/836094363",
      description:
        "I'm working to port my Android game to iOS and add extended functionality to the existing Android app.",
      btnText: "View Demo",
    },
    
  ];


  const skills = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

  const tools = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

  const lenguas = [
    "Agile Development",
    "Testing",
    "Debugging",
    "Git",
    "React.js",
    "Bootstrap",
    "Responsive Design",
    "UI/UX Design",
    "Database Management",
    "RESTful API Dev",
    "Auth",
    "Wireframing",
    "Prototyping",
    "Graphic Design",
    "3D Modeling",
    "Rendering",
    "Editing",
    "Motion Graphics"
  ];

export {PortfolioProjects, CurrentProjects, skills, tools, lenguas};
