// import './App.css';
import { useState, useEffect } from 'react'
import Button from './components/Button'
// import ButtonLink from './components/ButtonLink'
import Projects from './components/Projects'
import Current from './components/Current'
// import ReactPlayer from 'react-player'
import {BsFillMoonStarsFill, BsFillSunFill, BsTerminalFill, BsWrenchAdjustableCircleFill} from 'react-icons/bs'
import {AiFillLinkedin, AiFillGithub, AiFillBehanceCircle} from 'react-icons/ai'
import portrait from './media/portraitphoto.jpg'
import Skills from './components/Skills'
import Social from './components/Social'

function App() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const timeNow = new Date();
    const tnHour = timeNow.getHours();

    if (tnHour > 18 || tnHour < 6) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  // const devText = `designer(() => { developer })`;

  //'dark bg-slate-950' : 'bg-slate-50'
  // const devText = `(Developer & Designer)`;

  //${darkMode ? 'dark bg-slate-950' : 'bg-slate-50'}

  return (
    // <div className={darkMode ? "dark transition-all duration-300" : "transition-all duration-300"}>
    <div className={`flex w-screen justify-center  ${darkMode ? 'dark bg-slate-950' : 'bg-slate-50'}`}>
      <main className='max-sm:mx-1 sm:w-11/12 md:w-12/12 lg:w-10/12 xl:w-8/12 transition-all duration-300 z-0'>
        <section className='min-h-fit mb-12 px-5'>
          <nav className='py-10 mb-12 max-sm:mb-0 flex flex-wrap justify-between gap-5'>
              <h1 className='text-pearl-900 sm:text-md md:text-xl lg:text-xl font-semibold my-auto dark:text-slate-50 max-sm:w-full flex justify-between items-center transition-all duration-300 transition-width'>aaroncwilliams { darkMode ? <BsFillSunFill onClick={() => setDarkMode(!darkMode)} className='text-slate-50 ml-2 cursor-pointer'/> : <BsFillMoonStarsFill onClick={() => setDarkMode(!darkMode)} className='text-pearl-900 ml-2 cursor-pointer '/> }</h1>
              <ul className='flex items-center transition-width justify-evenly gap-2 max-lg:hidden transition-all duration-300'>
                <li className='min-w-[33.33%]'>
                  <Button link="https://github.com/awil" text="GitHub" cstyle="sheen flex justify-center min-w-[100%] align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-pearl-400 to-pearl-500 text-white transition-all duration-300 hover:text-pearl-900 px-2 py-2 rounded-md dark:text-pearl-950 dark:hover:text-white" icon={<AiFillGithub className='relative my-auto mr-1' size={18}/>} /></li>                  
                  <li className='min-w-[33.33%]'>
                  <Button link="https://www.linkedin.com/in/aaroncwilliams/" text="LinkedIn" cstyle="sheen flex justify-center min-w-[100%] align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-pearl-400 to-pearl-500 text-white transition-all duration-300 hover:text-pearl-900 px-2 py-2 rounded-md dark:text-pearl-950 dark:hover:text-white" icon={<AiFillLinkedin className='relative my-auto mr-1' size={18}/>} /></li>
                <li className='min-w-[33.33%]'>
                  <Button link="https://www.behance.net/aaroncwilliams" text="Behance" cstyle="sheen flex justify-center min-w-[100%] align-middle text-sm sm:text-md md:text-md lg:text-md xl:text-lg 2xl:text-lg font-bold bg-gradient-to-r from-pearl-400 to-pearl-500 text-white transition-all duration-300 hover:text-pearl-900 px-2 py-2 rounded-md dark:text-pearl-950 dark:hover:text-white" icon={<AiFillBehanceCircle className='relative my-auto mr-1' size={18}/>} /></li>
              </ul>

              
          </nav>
          <div className='text-center p-5 sm:p-3 max-sm:px-0'>
            <h2 className='greeting__title text-3xl py-2 max-sm:pb-0 font-semibold md:text-6xl'>Hi, I'm Aaron!</h2>
            {/* <h3 className='text-pearl-900 font-mono text-2xl py-2 md:text-3xl dark:text-slate-50'><code>{devText}</code></h3> */}
            <p className='text-left my-5 py-5 text-pearl-900 text-lg max-sm:leading-[1.5rem] sm:leading-[1.75rem] sm:text-xl md:leading-[2.4rem] md:text-xl lg:leading-[2.4rem] lg:text-xl xl:leading-[3.4rem] xl:text-2xl max-w-xxl mx-auto dark:text-pearl-500'>I'm a recent graduate in <strong>Application Development</strong> from Northwestern State University of Louisiana. I'm a <strong>full-stack developer</strong> with <strong>4 years of development experience and 14 years of professional experience.</strong> I love writing code for <em>applications, websites, and utilities.</em> I'm also the proud chihuahua dad of Erma Jean.</p>

            {/* <p className='text-left text-md my-5 py-5 leading-8 text-pearl-900 md:text-xl max-w-xxl mx-auto dark:text-pearl-500'>Hi, I’m Aaron! I recently graduated in Application Development from Northwestern State University, where I garnered a strong foundation in software development and programming across multiple platforms. With over 14 years of experience as a Multimedia Designer, I’ve become skilled at creating engaging visual experiences.
            <br/><br/>
            I’m passionate about problem-solving and creativity, always pushing myself to explore new learning opportunities and expand my skills. My goal is to combine my knowledge in technology and design to inspire others and drive growth personally and professionally.</p> */}
          </div>
          <div className='relative mx-auto mt-2 mb-6 bg-gradient-to-b from-amber-300 to-amber-600 rounded-full overflow-hidden w-60 h-60'>
              <img src={portrait} alt="Aaron Williams" className='object-cover w-full h-full' />
          </div>
          
          <Social></Social>

        </section>
        <section className='p-5 mt-10 mb-14'>
          <div>
            <h3 className='text-pearl-900 text-3xl py-1 md:text-4xl dark:text-pearl-500'>About Me</h3>
            <p className='text-left text-md my-5 py-5 sm:leading-8 text-pearl-900 md:leading-8 md:text-xl dark:text-pearl-500'>As a full stack developer, I bring a wide range of skills to the table. When it comes to the front-end, I'm all about creating awesome user interfaces using HTML, CSS, and JavaScript. I love working with newer frameworks, like React, enabling me to build dynamic and interactive web applications.
            <br/><br/>
            On the back-end, I have experience with server-side technologies such as PHP, MySQL, and Node.js. My knowledge extends to RESTful API development, ensuring seamless communication between front-end and back-end components.
            <br/><br/>
            My expertise extends beyond web development; I've also dabbled in Android app development, creating immersive mobile experiences using Java/Kotlin. Additionally, a little experience in iOS app development with Swift.
            <br/><br/>
            I'm an expert with design tools like Adobe Creative Suite, allowing me to create captivating visuals and engaging user interfaces. My ability to bridge the gap between development and design enables me to create holistic and cohesive digital experiences that are both visually stunning and highly functional.</p>
          </div>
          <div className='flex gap-4 flex-col md:flex-row'>
            <div className='text-center shadow-sm p-6 rounded-xl basis-1/2 dark:bg-slate-900'>
              <h2 className='font-bold text-pearl-950 dark:text-pearl-500'><BsTerminalFill className='w-20 h-20 scale-50 mx-auto' /></h2>
              <h3 className='py-4 text-pearl-900 text-xl font-bold dark:text-pearl-500'>Languages</h3>
              <ul className='py-1 text-pearl-900 dark:text-pearl-500'>
                <li>JavaScript</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Java</li>
                <li>Swift</li>
                <li>PHP</li>
                <li>MySQL</li>
              </ul>
            </div>
            <div className='text-center shadow-sm p-6 rounded-xl  basis-1/2 dark:bg-slate-900'>
                <h2 className='font-bold text-pearl-950 dark:text-pearl-500'><BsWrenchAdjustableCircleFill className='w-20 h-20 scale-50 mx-auto' /></h2>
              <h3 className='py-4 text-xl font-bold text-pearl-900 dark:text-pearl-500'>Tools</h3>
              <ul className='py-1 text-pearl-900 dark:text-pearl-500'>
                <li>IDEs (Eclipse, Xcode, Android Developer Studio)</li>
                <li>Database Tools (e.g. SSMS, MySQL Workbench)</li>
                <li>Command Line Interface Tools</li>
                <li>Adobe Creative Suite</li>
                <li>Blender 3D</li>
              </ul>
            </div>
          </div>
          <div className='flex gap-4 flex-col md:flex-row mb-12'>
            <div className='text-center shadow-sm  rounded-xl my-4 pb-10 basis-full dark:bg-slate-900'>
            <h2 className='font-bold text-pearl-950 dark:text-pearl-500'>
              <BsTerminalFill className='w-20 h-20 scale-50 mx-auto' /></h2>
            <h3 className='py-4 text-pearl-900 text-xl font-bold dark:text-pearl-500'>Skills</h3>
            <div className='flex flex-row basis-full mx-5'>
            <ul className='basis-1/2 py-1 text-pearl-900 dark:text-pearl-500 text-center'>
              <li>Agile Development</li>
              <li>Testing</li>
              <li>Debugging</li>
              <li>Git</li>
              <li>Responsive Design</li>
              <li>UI/UX Design</li>
              <li>Database Management</li>
              <li>RESTful APIs</li>
            </ul>
            <ul className='basis-1/2 py-1 text-pearl-900 dark:text-pearl-500 text-center'>
              <li>Wireframing</li>
              <li>Prototyping</li>
              <li>Graphic Design</li>
              <li>3D Modeling</li>
              <li>Rendering</li>
              <li>Multimedia Editing</li>
              <li>Motion Graphics</li>
            </ul>
            </div>

            </div>
          </div>
        </section>

        <Projects></Projects>

        <Current></Current>

        <footer className='pt-5 mt-10 pb-12'>
          
          <Social></Social>

        </footer>
        
      </main>
    </div>
  );
}

export default App;
