import React from 'react';

const Button = ({ link, text, cstyle, icon }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
        <button className={cstyle}>
            {icon}{text}
        </button>
    </a>
  );
};

export default Button;