import React from 'react';
import {BsVimeo} from 'react-icons/bs';
import {AiFillTwitterCircle, AiFillLinkedin, AiFillGithub, AiFillBehanceCircle} from 'react-icons/ai';

const Social = () => {
  return (
    <div className='text-4xl flex justify-center gap-16 max-sm:gap-6 py-3 text-pearl-600 md:text-5xl dark:text-pearl-500'>
        <a href="https://www.linkedin.com/in/aaroncwilliams/" className='hover:text-pearl-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillLinkedin /></a>
        <a href="https://twitter.com/aaroncwilliams" className='hover:text-pearl-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillTwitterCircle /></a>
        <a href="https://github.com/awil" className='hover:text-pearl-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillGithub /></a>
        <a href="https://www.behance.net/aaroncwilliams" className='hover:text-pearl-600 transition-all duration-300' target="_blank" rel="noreferrer"><AiFillBehanceCircle /></a>
        <a href="https://vimeo.com/aaroncwilliams" className='hover:text-pearl-600 transition-all duration-300' target="_blank" rel="noreferrer"><BsVimeo /></a>
    </div>
  )
}

export default Social